<template>
  <div>
    <a-card
      v-for="(item, index) in itmsConOrderData"
      :key="index"
      title="订单信息"
    >
      <div slot="extra">
        <a-button v-if="index === 0">
          <router-link :to="{ name: 'ItmsConsignForShipment' }">取消</router-link>
        </a-button>
        <a-button type="primary" @click="handleSubmit" v-if="index === 0" style="margin-left: 10px;">保存</a-button>
        <a-button type="query" @click="addModule(index)" style="margin-left: 10px;">添加</a-button>
        <a-button type="danger" @click="deleteModule(index)" v-if="index !== 0" style="margin-left: 10px;">删除</a-button>
      </div>
      <a-form-model layout="vertical">
        <a-row :gutter="24">
          <a-col :span="4">
            <a-form-model-item
              label="业务日期"
              :prop="`itmsConOrderData.${index}.business_date`"
              :rules="[
                {
                  required: 'true',
                  message: '请选择业务日期',
                  trigger: 'blur'
                }
              ]"
            >
              <a-date-picker v-model="itmsConOrderData[index].business_date" style="width: 100%" format="YYYY-MM-DD" />
            </a-form-model-item>
          </a-col>
          <a-col :span="4">
            <a-form-model-item label="截港时间">
              <a-date-picker v-model="itmsConOrderData[index].discharge_port_date" style="width: 100%" format="YYYY-MM-DD" />
            </a-form-model-item>
          </a-col>
          <a-col :span="4">
            <a-form-model-item
              label="客户"
              :prop="`itmsConOrderData.${index}.customer_id`"
              :rules="[
                {
                  required: 'true',
                  message: '请选择客户',
                  trigger: 'blur'
                }
              ]"
            >
              <a-select
                v-model="itmsConOrderData[index].customer_id"
                placeholder="请选择客户"
                style="width: 100%"
              >
                <a-select-option v-for="op in customerData" :key="op.customer_id">
                  {{ op.nick_name }}
                </a-select-option>
              </a-select>
            </a-form-model-item>
          </a-col>
          <a-col :span="4">
            <a-form-model-item
              label="委托车队"
              :prop="`itmsConOrderData.${index}.supplier_id`"
              :rules="[
                {
                  required: 'true',
                  message: '请选择委托车队',
                  trigger: 'blur'
                }
              ]"
            >
              <a-select
                v-model="itmsConOrderData[index].supplier_id"
                placeholder="请选择委托车队"
                style="width: 100%"
              >
                <a-select-option v-for="op in supplierData" :key="op.customer_id">
                  {{ op.nick_name }}
                </a-select-option>
              </a-select>
            </a-form-model-item>
          </a-col>
          <a-col :span="4">
            <a-form-model-item label="EP号">
              <a-input v-model="itmsConOrderData[index].ep_no" placeholder="请输入EP号" @blur="existEpNo(index)"/>
            </a-form-model-item>
          </a-col>
          <a-col :span="4">
            <a-form-model-item label="拖运编号">
              <a-input v-model="itmsConOrderData[index].business_no" placeholder="请输入拖运编号" @blur="existBusinessNo(index)"/>
            </a-form-model-item>
          </a-col>
          <a-col :span="4">
            <a-form-model-item label="调度人姓名">
              <a-select
                v-model="itmsConOrderData[index].dispatch_name"
                placeholder="请选择调度人姓名"
                style="width: 100%"
                showSearch
                :filter-option="filterOption"
              >
                <a-select-option v-for="op in dispatchNameData" :key="op.value">
                  {{ op.name }}
                </a-select-option>
              </a-select>
            </a-form-model-item>
          </a-col>
          <a-col :span="6">
            <a-form-model-item label="备注">
              <a-input v-model="itmsConOrderData[index].remark" />
            </a-form-model-item>
          </a-col>
          <a-col :span="2">
            <a-form-model-item label="总净值">
              <a-input v-model="itmsConOrderData[index].charge_net_sum_amount" disabled />
            </a-form-model-item>
          </a-col>
          <a-col :span="2">
            <a-form-model-item label="总税额">
              <a-input v-model="itmsConOrderData[index].tax_sum_amount" disabled />
            </a-form-model-item>
          </a-col>
          <a-col :span="2">
            <a-form-model-item label="总应收">
              <a-input v-model="itmsConOrderData[index].charge_sum_amount" disabled />
            </a-form-model-item>
          </a-col>
          <a-col :span="2">
            <a-form-model-item label="总应付">
              <a-input v-model="itmsConOrderData[index].pay_sum_amount" disabled />
            </a-form-model-item>
          </a-col>
          <a-col :span="2">
            <a-form-model-item label="总利润">
              <a-input v-model="itmsConOrderData[index].profit_sum_amount" disabled />
            </a-form-model-item>
          </a-col>
        </a-row>
        <a-button type="primary" ghost @click="addBoxInfoModule(index)" size="small" style="margin-bottom: 10px;">
          添加箱信息
        </a-button>
        <div
          v-for="(boxInfo, boxIndex) in itmsConOrderData[index].box_info_list"
          :key="boxIndex"
          :gutter="24"
        >
          <a-row :gutter="24">
            <a-col :span="4">
              <a-form-model-item label="JOB号">
                <a-input v-model="itmsConOrderData[index].box_info_list[boxIndex].job_no" placeholder="请输入JOB号"/>
              </a-form-model-item>
            </a-col>
            <a-col :span="2">
              <a-form-model-item label="箱型">
                <a-select
                  v-model="itmsConOrderData[index].box_info_list[boxIndex].container_type"
                  style="width: 100%"
                  allowClear
                  showSearch
                  :filter-option="filterOption"
                >
                  <a-select-option v-for="op in containerTypeData" :key="op.value">{{ op.name }}</a-select-option>
                </a-select>
              </a-form-model-item>
            </a-col>
            <a-col :span="2">
              <a-form-model-item label="箱尺寸">
                <a-select
                  v-model="itmsConOrderData[index].box_info_list[boxIndex].container_size"
                  style="width: 100%"
                  allowClear
                  @change="matchConPriceInfo(index, boxIndex)"
                >
                  <a-select-option v-for="op in containerSizeData" :key="op.value">{{ op.name }}</a-select-option>
                </a-select>
              </a-form-model-item>
            </a-col>
            <a-col :span="2">
              <a-form-model-item label="数量">
                <a-input v-model="itmsConOrderData[index].box_info_list[boxIndex].num" @blur="calcPrice(index, boxIndex)"/>
              </a-form-model-item>
            </a-col>
            <a-col :span="3">
              <a-form-model-item label="存箱地">
                <a-select
                  v-model="itmsConOrderData[index].box_info_list[boxIndex].start_point_id"
                  style="width: 100%"
                  @change="matchConPriceInfo(index, boxIndex)"
                  showSearch
                  :filter-option="filterOption"
                >
                  <a-select-option v-for="op in pointData" :key="op.id">
                    {{ op.point_name }}
                  </a-select-option>
                </a-select>
              </a-form-model-item>
            </a-col>
            <a-col :span="3">
              <a-form-model-item label="流向点">
                <a-select
                  v-model="itmsConOrderData[index].box_info_list[boxIndex].end_point_id"
                  style="width: 100%"
                  @change="matchConPriceInfo(index, boxIndex)"
                  showSearch
                  :filter-option="filterOption"
                >
                  <a-select-option v-for="op in pointData" :key="op.id">
                    {{ op.point_name }}
                  </a-select-option>
                </a-select>
              </a-form-model-item>
            </a-col>
          </a-row>
          <a-row :gutter="24">
            <a-col :span="2">
              <a-form-model-item label="应收单价">
                <a-input v-model="itmsConOrderData[index].box_info_list[boxIndex].charge_unit_price" @blur="calcPrice(index, boxIndex)" />
              </a-form-model-item>
            </a-col>
            <a-col :span="2">
              <a-form-model-item label="应收净值">
                <a-input v-model="itmsConOrderData[index].box_info_list[boxIndex].charge_net_price" @blur="calcPrice(index, boxIndex)" />
              </a-form-model-item>
            </a-col>
            <a-col :span="2">
              <a-form-model-item label="应收净值合计">
                <a-input v-model="itmsConOrderData[index].box_info_list[boxIndex].total_charge_net_price" @blur="calcTotal(index)" />
              </a-form-model-item>
            </a-col>
            <a-col :span="2">
              <a-form-model-item label="税额">
                <a-input v-model="itmsConOrderData[index].box_info_list[boxIndex].tax_amount" @blur="calcTotal(index)" />
              </a-form-model-item>
            </a-col>
            <a-col :span="2">
              <a-form-model-item label="应收合计">
                <a-input v-model="itmsConOrderData[index].box_info_list[boxIndex].total_charge_price" @blur="calcPrice2(index, boxIndex)" />
              </a-form-model-item>
            </a-col>
            <a-col :span="2">
              <a-form-model-item label="应付单价">
                <a-input v-model="itmsConOrderData[index].box_info_list[boxIndex].pay_unit_price" />
              </a-form-model-item>
            </a-col>
            <a-col :span="2">
              <a-form-model-item label="应付合计">
                <a-input v-model="itmsConOrderData[index].box_info_list[boxIndex].total_pay_price" @blur="calcPrice2(index, boxIndex)" />
              </a-form-model-item>
            </a-col>
            <a-col :span="2">
              <a-form-model-item label="利润">
                <a-input v-model="itmsConOrderData[index].box_info_list[boxIndex].profit" disabled />
              </a-form-model-item>
            </a-col>
            <a-col :span="2">
              <a-form-model-item label="业务类型">
                <a-input v-model="itmsConOrderData[index].box_info_list[boxIndex].business_type" />
              </a-form-model-item>
            </a-col>
            <a-col :span="1">
              <a-form-model-item label="操作">
                <a-button type="danger" ghost @click="deleteBoxInfoModule(index, boxIndex)">删除</a-button>
              </a-form-model-item>
            </a-col>
          </a-row>
        </div>
      </a-form-model>
    </a-card>
  </div>
</template>

<script>
import {
  saveBatchItmsConOrder,
  updateItmsConOrder,
  getItmsConOrderById,
  matchConPrice,
  getPointInit,
  existItmsOrderEpNo,
  existItmsOrderJOBNo,
  existItmsOrderBusinessNo,
  initItmsConCustomerInfo
} from '@/api/itms'
import moment from 'moment'
import { getFirstLetter } from '@/utils/util'

export default {
  name: 'ItmsConsignForShipmentCUR',
  data() {
    return {
      pointData: [],
      itmsConOrderData: [
        {
          business_date: null, // 业务日期
          discharge_port_date: null, // 截港时间
          ep_no: null, // ep 号码
          business_no: null, // 拖运编号
          dispatch_name: null, // 调度人
          customer_id: null, // 客户
          supplier_id: null, // 委托车队
          remark: null, // 备注
          charge_net_sum_amount: 0, // 总净值
          tax_sum_amount: 0, // 总税额
          charge_sum_amount: 0, // 总应收
          pay_sum_amount: 0, // 总应付
          profit_sum_amount: 0, // 总利润
          // 箱信息集合
          box_info_list: [{
            job_no: null, // job 号码
            container_type: null, // 箱型
            container_size: null, // 箱尺寸
            num: null, // 数量
            start_point_id: null, // 存箱地
            end_point_id: null, // 流向点
            charge_unit_price: 0, // 应收单价
            charge_net_price: 0, // 应收净值
            total_charge_price: 0, // 应收合计
            total_charge_net_price: 0, // 应收净值合计
            pay_unit_price: 0, // 应付单价
            total_pay_price: 0, // 应付合计
            profit: 0, // 利润
            tax_amount: 0, // 税额
            business_type: null
          }]
        }
      ],
      // 是否编辑
      isEdit: false,
      dispatchNameData: [
        { value: '陈琦', name: '陈琦' },
        { value: '陈有来', name: '陈有来' },
        { value: '柴一帆', name: '柴一帆' },
        { value: '俞齐骏', name: '俞齐骏' },
        { value: '孙智慧', name: '孙智慧' },
        { value: 'TOM TAO', name: 'TOM TAO' },
        { value: '陈佳杰', name: '陈佳杰' },
        { value: '龚思健', name: '龚思健' },
        { value: '苏成勇', name: '苏成勇' }
      ],
      containerSizeData: [
        { value: '20', name: '20' },
        { value: '40', name: '40' },
        { value: '45', name: '45' }
      ],
      containerTypeData: [
        { value: 'GP', name: 'GP' },
        { value: 'RQ', name: 'RQ' },
        { value: 'RF', name: 'RF' },
        { value: 'HQ', name: 'HQ' },
        { value: 'FL', name: 'FL' },
        { value: 'OT', name: 'OT' },
        { value: 'HC', name: 'HC' },
        { value: 'RH', name: 'RH' },
        { value: 'OH', name: 'OH' },
        { value: 'FR', name: 'FR' },
        { value: 'FQ', name: 'FQ' },
        { value: 'TK', name: 'TK' },
        { value: 'HT', name: 'HT' },
        { value: 'HG', name: 'HG' },
        { value: 'AR', name: 'AR' },
        { value: 'BU', name: 'BU' },
        { value: 'BQ', name: 'BQ' },
        { value: 'PF', name: 'PF' },
        { value: 'SR', name: 'SR' },
        { value: 'SQ', name: 'SQ' }
      ],
      customerData: [],
      supplierData: []
    }
  },
  watch: {
    $route(newRoute) {
      if (newRoute.name === 'ItmsConsignForShipmentCUR') {
        this.clearData()
        if (newRoute.params.id === 'create') {
          this.isEdit = false
          this.$route.meta.title = '新增拖运信息'
        } else {
          this.isEdit = true
          this.$route.meta.title = '编辑拖运信息'
          this.getOrderDetailById(newRoute.params.id)
        }
      }
    }
  },
  mounted() {
    if (this.$route.params.id !== 'create') {
      this.isEdit = true
      this.$route.meta.title = '编辑拖运信息'
      this.getOrderDetailById(this.$route.params.id)
    }
    this.clearData()
    getPointInit().then(res => {
      this.pointData = res
    })
    initItmsConCustomerInfo().then(res => {
      this.customerData = res.CUSTOMER_LIST
      this.supplierData = res.SUPPLIER_LIST
    })
  },
  methods: {
    existEpNo(index) {
      if (this.itmsConOrderData[index].ep_no) {
        const reqParam = {
          'ep_no': this.itmsConOrderData[index].ep_no
        }
        existItmsOrderEpNo(reqParam).then(res => {})
      }
    },
    existJobNo(index) {
      if (this.itmsConOrderData[index].job_no) {
        const reqParam = {
          'job_no': this.itmsConOrderData[index].job_no
        }
        existItmsOrderJOBNo(reqParam).then(res => {})
      }
    },
    existBusinessNo(index) {
      if (this.itmsConOrderData[index].business_no) {
        const reqParam = {
          'business_no': this.itmsConOrderData[index].business_no
        }
        existItmsOrderBusinessNo(reqParam).then(res => {})
      }
    },
    // 动态计算价格
    calcPrice(index, boxIndex) {
      const boxInfo = this.itmsConOrderData[index].box_info_list[boxIndex]
      let chargeTotal = 0
      let payTotal = 0
      if (boxInfo.num && boxInfo.charge_net_price) {
        // 应收净值合计
        const totalChargeNetPrice = parseFloat(boxInfo.num * boxInfo.charge_net_price).toFixed(2)
        this.$set(this.itmsConOrderData[index].box_info_list[boxIndex], 'total_charge_net_price', totalChargeNetPrice)
        // 税额
        const taxAmount = parseFloat(parseFloat(totalChargeNetPrice) * 0.09).toFixed(2)
        this.$set(this.itmsConOrderData[index].box_info_list[boxIndex], 'tax_amount', taxAmount)
        // 应收合计
        chargeTotal = parseFloat(parseFloat(totalChargeNetPrice) + parseFloat(taxAmount)).toFixed(2)
        this.$set(this.itmsConOrderData[index].box_info_list[boxIndex], 'total_charge_price', chargeTotal)
      }
      if (boxInfo.num && boxInfo.pay_unit_price) {
        // 应付合计
        payTotal = parseFloat(boxInfo.num * boxInfo.pay_unit_price).toFixed(2)
        this.$set(this.itmsConOrderData[index].box_info_list[boxIndex], 'total_pay_price', payTotal)
      }
      // 利润
      const profit = parseFloat(parseFloat(chargeTotal) - parseFloat(payTotal)).toFixed(2)
      this.$set(this.itmsConOrderData[index].box_info_list[boxIndex], 'profit', profit)
    },
    // 动态计算利润
    calcPrice2(index, boxIndex) {
      // 应收合计
      const chargeTotal = this.itmsConOrderData[index].box_info_list[boxIndex].total_charge_price
      // 应付合计
      const payTotal = this.itmsConOrderData[index].box_info_list[boxIndex].total_pay_price
      // 重新计算利润
      const profit = parseFloat(chargeTotal - payTotal).toFixed(2)
      this.$set(this.itmsConOrderData[index].box_info_list[boxIndex], 'profit', profit)
      // 应收、应付变化、重新触发计算订单总应收、总应付合计
      this.calcTotal(index)
    },
    // 动态计算订单总合计
    calcTotal(index) {
      // 订单总净值重新计算
      let chargeNetSumAmount = 0
      this.itmsConOrderData[index].box_info_list.forEach(item => {
        chargeNetSumAmount += parseFloat(item.total_charge_net_price)
      })
      this.$set(this.itmsConOrderData[index], 'charge_net_sum_amount', chargeNetSumAmount.toFixed(2))

      // 订单总税额重新计算
      let taxSumAmount = 0
      this.itmsConOrderData[index].box_info_list.forEach(item => {
        taxSumAmount += parseFloat(item.tax_amount)
      })
      this.$set(this.itmsConOrderData[index], 'tax_sum_amount', taxSumAmount.toFixed(2))

      // 订单总应收重新计算
      let chargeSumAmount = 0
      this.itmsConOrderData[index].box_info_list.forEach(item => {
        chargeSumAmount += parseFloat(item.total_charge_price)
      })
      this.$set(this.itmsConOrderData[index], 'charge_sum_amount', chargeSumAmount.toFixed(2))

      // 订单总应付重新计算
      let paySumAmount = 0
      this.itmsConOrderData[index].box_info_list.forEach(item => {
        paySumAmount += parseFloat(item.total_pay_price)
      })
      this.$set(this.itmsConOrderData[index], 'pay_sum_amount', paySumAmount.toFixed(2))

      // 订单总利润重新计算
      const profitSumAmount = parseFloat(parseFloat(chargeSumAmount) - parseFloat(paySumAmount)).toFixed(2)
      this.$set(this.itmsConOrderData[index], 'profit_sum_amount', profitSumAmount)
    },
    // 匹配价格
    matchConPriceInfo(index, boxIndex) {
      const reqParam = {
        'container_size': this.itmsConOrderData[index].box_info_list[boxIndex].container_size,
        'start_point_id': this.itmsConOrderData[index].box_info_list[boxIndex].start_point_id,
        'end_point_id': this.itmsConOrderData[index].box_info_list[boxIndex].end_point_id
      }
      matchConPrice(reqParam).then(res => {
        this.$set(this.itmsConOrderData[index].box_info_list[boxIndex], 'charge_unit_price', res.charge_unit_price)
        this.$set(this.itmsConOrderData[index].box_info_list[boxIndex], 'charge_net_price', res.charge_net_price)
        this.$set(this.itmsConOrderData[index].box_info_list[boxIndex], 'pay_unit_price', res.pay_unit_price)
        // 匹配到价格后主动计算一次合计
        this.calcPrice(index, boxIndex)
      })
    },
    getOrderDetailById(id) {
      getItmsConOrderById(id).then(res => {
        const boxInfoData = []
        res.box_info_list.forEach(item => {
          boxInfoData.push({
            job_no: item.job_no,
            container_type: item.container_type,
            container_size: item.container_size,
            num: item.num,
            start_point_id: item.start_point_id,
            end_point_id: item.end_point_id,
            charge_unit_price: item.charge_unit_price.toFixed(2),
            total_charge_price: item.total_charge_price.toFixed(2),
            charge_net_price: item.charge_net_price.toFixed(2),
            total_charge_net_price: item.total_charge_net_price.toFixed(2),
            pay_unit_price: item.pay_unit_price.toFixed(2),
            total_pay_price: item.total_pay_price.toFixed(2),
            tax_amount: item.tax_amount.toFixed(2),
            profit: item.profit.toFixed(2),
            business_type: item.business_type
          })
        })
        this.itmsConOrderData = []
        this.itmsConOrderData.push({
          id: res.id,
          business_date: res.business_date,
          discharge_port_date: res.discharge_port_date,
          ep_no: res.ep_no,
          job_no: res.job_no,
          business_no: res.business_no,
          dispatch_name: res.dispatch_name,
          customer_id: res.customer_id,
          supplier_id: res.supplier_id,
          remark: res.remark,
          charge_net_sum_amount: res.charge_net_sum_amount,
          tax_sum_amount: res.tax_sum_amount,
          charge_sum_amount: res.charge_sum_amount,
          pay_sum_amount: res.pay_sum_amount,
          profit_sum_amount: parseFloat(res.charge_sum_amount - res.pay_sum_amount).toFixed(2),
          box_info_list: boxInfoData
        })
      })
    },
    clearData() {
      this.itmsConOrderData = [
        {
          business_date: null,
          discharge_port_date: null,
          ep_no: null,
          job_no: null,
          business_no: null,
          dispatch_name: null,
          customer_id: null,
          supplier_id: null,
          remark: null,
          charge_net_sum_amount: 0,
          tax_sum_amount: 0,
          charge_sum_amount: 0,
          pay_sum_amount: 0,
          profit_sum_amount: 0,
          box_info_list: [{
            job_no: null,
            container_type: null,
            container_size: null,
            num: null,
            start_point_id: null,
            end_point_id: null,
            charge_unit_price: 0,
            charge_net_price: 0,
            total_charge_price: 0,
            total_charge_net_price: 0,
            pay_unit_price: 0,
            total_pay_price: 0,
            profit: 0,
            tax_amount: 0,
            business_type: null
          }]
        }
      ]
    },
    handleSubmit() {
      this.itmsConOrderData.forEach(item => {
        if (item.business_date) {
          // 日期需要转换格式为时间戳、后台使用 Date 类型接收
          item.business_date = moment(item.business_date).valueOf()
        }
        if (item.discharge_port_date) {
          item.discharge_port_date = moment(item.discharge_port_date).valueOf()
        }
      })
      let func
      let reqParam
      if (this.isEdit) {
        // 编辑
        func = updateItmsConOrder
        reqParam = {
          ...this.itmsConOrderData[0]
        }
      } else {
        // 新增
        func = saveBatchItmsConOrder
        reqParam = {
          'order_list': this.itmsConOrderData
        }
      }
      func(reqParam).then(res => {
        this.$notification['success']({
          message: '提示',
          description: this.isEdit ? '编辑成功' : '新增成功'
        })
        this.$router.push({
          name: 'ItmsConsignForShipment'
        })
      })
    },
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
        getFirstLetter(option.componentOptions.children[0].text)
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      )
    },
    addModule(index) {
      this.itmsConOrderData.push({
        business_date: null,
        discharge_port_date: null,
        ep_no: null,
        job_no: null,
        business_no: null,
        dispatch_name: null,
        customer_id: null,
        supplier_id: null,
        remark: null,
        charge_net_sum_amount: 0,
        tax_sum_amount: 0,
        charge_sum_amount: 0,
        pay_sum_amount: 0,
        profit_sum_amount: 0,
        box_info_list: []
      })
    },
    deleteModule(index) {
      if (index !== 0) {
        this.itmsConOrderData.splice(index, 1)
        return true
      } else {
        return false
      }
    },
    addBoxInfoModule(index) {
      this.itmsConOrderData[index].box_info_list.push({
        job_no: null,
        container_type: null,
        container_size: null,
        num: null,
        start_point_id: null,
        end_point_id: null,
        charge_unit_price: 0,
        charge_net_price: 0,
        total_charge_price: 0,
        total_charge_net_price: 0,
        pay_unit_price: 0,
        total_pay_price: 0,
        profit: 0,
        tax_amount: 0,
        business_type: null
      })
    },
    deleteBoxInfoModule(index, boxIndex) {
      this.itmsConOrderData[index].box_info_list.splice(boxIndex, 1)
    }
  }
}
</script>

<style scoped>

</style>
